.navbar {
    /* background: rgba(255, 255, 255, 0.1);  */
    background: rgba(255, 255, 255, 0.493); 

    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); 
    color: white;
    padding: 15px 0;
    font-family: Arial, sans-serif;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}
  
.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    max-width: 100%;
    margin: 0 auto;
}

.navbar-logo {
    flex-grow: 1;
}

.navbar-logo img {
    height: 40px; /* Set the height of the logo image */
    width: auto;
}

.navbar-menu {
    display: flex;
    list-style-type: none;
    margin: 0;
    padding: 0;
    align-items: center;
}

.navbar-item {
    margin: 0 15px;
}

.navbar-link {
    color: #007bff; /* Blue color for menu items */
    text-decoration: none;
    font-size: 16px;
    transition: color 0.3s ease;
    font-weight: bold;
}

.navbar-link:hover {
    color: #53a6ff; /* Highlight color */
}

.navbar-donate .donate-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    transition: background-color 0.3s ease;
}

.navbar-donate .donate-button:hover {
    background-color: #53a6ff; /* Hover color */
}

.navbar-hamburger {
    display: none;
    cursor: pointer;
}

/* For active menu on mobile */
.navbar-menu.active {
    display: block;
}

.navbar-menu {
    display: flex;
}

@media (max-width: 768px) {
    .navbar-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .navbar-menu {
        flex-direction: column;
        width: 100%;
        display: none;
        padding-top: 20px;
    }

    .navbar-item {
        margin: 10px 0;
    }

    .navbar-hamburger {
        display: block;
        position: absolute;
        right: 20px; /* Move hamburger to the right */
        top: 20px;
        color: #007bff;
        font-size: 1.6rem;
        font-weight: bold;
    }

    .navbar-menu.active {
        display: flex;
    }

    .navbar-donate {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }

    .navbar-donate .donate-button {
        width: 100%;
        padding: 12px 20px;
        margin: 10px 0;
    }
}
